import React, { useState } from "react";
import newPasswordStyles from "../../pages/password/styles/newPasswordStyles";
import Text from "../../components/common/ui_kit/text";
import Row from "../../components/common/ui_kit/row";
import CustomModal from "../../components/inputs/custom_modal";
import { color, pxToRem } from "../../utilities/themes";
import Close_Icon from "../../components/data_display/icons/Close";
import DemoVideoModal from "./DemoVideoModal";
import VideoBotDemoModal from "./VideoBotDemoModal";
import DemoCompanyVideoModal from "./DemoCompanyVideoModal";
import DemoPostJobModal from "./DemoPostJobModal";
import VideoDisplayModal from "./VideoDisplayModal";

function DemoModal(props) {
  const { classes, onClose, openDemoModal } = props;
  const [openDemoVideoModal, setDemoVideoModal] = useState(false);
  const [openDemoCompanyVideoModal, setDemoCompanyVideoModal] = useState(false);
  const [openDemoVideoBotModal, setDemoVideoBotModal] = useState(false);
  const [openDemoPostJobModal, setDemoPostJobModal] = useState(false);
  const [openVideoModal, setOpenVideoModal] = useState(false);
  const [selectedVideoUrl, setSelectedVideoUrl] = useState(false);
  const [selectedModalTitle, setSelectedModalTitle] = useState("");

  const toggleVideoModal = (url, modalTitle) => () => {
    setOpenVideoModal(!openVideoModal);
    setSelectedVideoUrl(url);
    setSelectedModalTitle(modalTitle);
  };

  const toggleDemoVideoModal = () => {
    setDemoVideoModal(!openDemoVideoModal);
  };

  const toggleDemoVideoBotModal = () => {
    setDemoVideoBotModal(!openDemoVideoBotModal);
  };

  const toggleDemoPostJobModal = () => {
    setDemoPostJobModal(!openDemoPostJobModal);
  };

  const toggleCompanyDemoVideoModal = () => {
    setDemoCompanyVideoModal(!openDemoCompanyVideoModal);
  };

  return (
    <>
      <CustomModal
        open={openDemoModal}
        aria-labelledby="modal-title"
        disablePortal
        aria-describedby="modal-description"
        disableAutoFocus
      >
        <div className={classes.demoModalWidth}>
          <div>
            <Close_Icon onClick={onClose} className={classes.closeAlign} />
          </div>

          <div className={classes.textCenter}>
            <img
              src="/assets/images/movie-icon.png"
              style={{ width: pxToRem(100), height: pxToRem(100) }}
              className={classes.forgot_email_img}
            />
          </div>
          <div style={{ padding: "20px" }}>
            <div>
              <Text
                family="gillsans_sb"
                size={22}
                className={classes.headerText}
              >
                Company Demos
              </Text>
              <Row>
                <img
                  src="/assets/images/arrow-icon.png"
                  className={classes.arrow_icon}
                />
                <Text
                  family="gillsans_r"
                  size={18}
                  className={classes.optionsAlign}
                  onClick={toggleDemoPostJobModal}
                >
                  How to Post a Job
                </Text>
              </Row>
              <Row>
                <img
                  src="/assets/images/arrow-icon.png"
                  className={classes.arrow_icon}
                />
                <Text
                  family="gillsans_r"
                  size={18}
                  className={classes.optionsAlign}
                  onClick={toggleDemoVideoBotModal}
                >
                  Use our VideoBot for Automated First Round Interviews{" "}
                  <img
                    src="/assets/images/Annie Hover.png"
                    style={{ height: "20px" }}
                  />
                </Text>
              </Row>
              <Row>
                <img
                  src="/assets/images/arrow-icon.png"
                  className={classes.arrow_icon}
                />
                <Text
                  family="gillsans_r"
                  size={18}
                  className={classes.optionsAlign}
                  onClick={toggleCompanyDemoVideoModal}
                >
                  Build a Company Profile
                </Text>
              </Row>
            </div>
            <div style={{ marginTop: "20px" }}>
              <Text
                family="gillsans_sb"
                size={22}
                className={classes.headerText}
              >
                Individual Demos
              </Text>
              <Row>
                <img
                  src="/assets/images/arrow-icon.png"
                  className={classes.arrow_icon}
                />
                <Text
                  family="gillsans_r"
                  size={18}
                  className={classes.optionsAlign}
                  onClick={toggleDemoVideoModal}
                >
                  Build Your Personal Online Profile{" "}
                  <img
                    src="/assets/images/Resume.png"
                    style={{ height: "20px" }}
                  />
                </Text>
              </Row>
              <Row>
                <img
                  src="/assets/images/arrow-icon.png"
                  className={classes.arrow_icon}
                />
                <Text
                  family="gillsans_r"
                  size={18}
                  className={classes.optionsAlign}
                  onClick={toggleVideoModal(
                    "https://player.vimeo.com/video/1055314847?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479",
                    "Apply to a Job"
                  )}
                >
                  Apply to a Job
                </Text>
              </Row>
              <Row>
                <img
                  src="/assets/images/arrow-icon.png"
                  className={classes.arrow_icon}
                />
                <Text
                  family="gillsans_r"
                  size={18}
                  className={classes.optionsAlign}
                >
                  Finding your Dream Job
                </Text>
              </Row>
            </div>
            <div style={{ marginTop: "20px" }}>
              <Text
                family="gillsans_sb"
                size={22}
                className={classes.headerText}
              >
                Coaching
              </Text>
              <Row>
                <img
                  src="/assets/images/arrow-icon.png"
                  className={classes.arrow_icon}
                />
                <Text
                  family="gillsans_r"
                  size={18}
                  className={classes.optionsAlign}
                  onClick={toggleVideoModal(
                    "https://player.vimeo.com/video/1054387178?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479",
                    "Tricks of the Trade"
                  )}
                >
                  Tricks of the Trade
                </Text>
              </Row>
              <Row>
                <img
                  src="/assets/images/arrow-icon.png"
                  className={classes.arrow_icon}
                />
                <Text
                  family="gillsans_r"
                  size={18}
                  className={classes.optionsAlign}
                  onClick={toggleVideoModal(
                    "https://player.vimeo.com/video/1055264165?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479",
                    "The Cover Letter Engine"
                  )}
                >
                  The Cover Letter Engine
                </Text>
              </Row>
              <Row>
                <img
                  src="/assets/images/arrow-icon.png"
                  className={classes.arrow_icon}
                />
                <Text
                  family="gillsans_r"
                  size={18}
                  className={classes.optionsAlign}
                  onClick={toggleVideoModal(
                    "https://player.vimeo.com/video/1055306413?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479",
                    "Wizard Boxes"
                  )}
                >
                  Wizard Boxes
                </Text>
              </Row>
            </div>
            <div style={{ marginTop: "20px" }}>
              <Text
                family="gillsans_sb"
                size={22}
                className={classes.headerText}
              >
                General Demos
              </Text>
              <Row>
                <img
                  src="/assets/images/arrow-icon.png"
                  className={classes.arrow_icon}
                />
                <Text
                  family="gillsans_r"
                  size={18}
                  className={classes.optionsAlign}
                  onClick={toggleVideoModal(
                    "https://player.vimeo.com/video/1054387576?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479",
                    "Overview of TradeWorks for Architecture Firms"
                  )}
                >
                  Overview of TradeWorks for Architecture Firms
                </Text>
              </Row>
              <Row>
                <img
                  src="/assets/images/arrow-icon.png"
                  className={classes.arrow_icon}
                />
                <Text
                  family="gillsans_r"
                  size={18}
                  className={classes.optionsAlign}
                  onClick={toggleVideoModal(
                    "https://player.vimeo.com/video/1054387515?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479",
                    "Overview of TradeWorks for Architects"
                  )}
                >
                  Overview of TradeWorks for Architects
                </Text>
              </Row>
            </div>
          </div>
        </div>
      </CustomModal>
      {openDemoVideoModal && (
        <DemoVideoModal
          onClose={toggleDemoVideoModal}
          openDemoVideoModal={openDemoVideoModal}
        />
      )}
      {openDemoCompanyVideoModal && (
        <DemoCompanyVideoModal
          onClose={toggleCompanyDemoVideoModal}
          openDemoVideoModal={openDemoCompanyVideoModal}
        />
      )}
      {openDemoVideoBotModal && (
        <VideoBotDemoModal
          onClose={toggleDemoVideoBotModal}
          openDemoVideoModal={openDemoVideoBotModal}
        />
      )}
      {openDemoPostJobModal && (
        <DemoPostJobModal
          onClose={toggleDemoPostJobModal}
          openDemoVideoModal={openDemoPostJobModal}
        />
      )}
      {openVideoModal && (
        <VideoDisplayModal
          onClose={toggleVideoModal()}
          openDemoVideoModal={openVideoModal}
          videoUrl={selectedVideoUrl}
          modalTitle={selectedModalTitle}
        />
      )}
    </>
  );
}

export default newPasswordStyles(DemoModal);
