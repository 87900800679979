import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import { withStyles } from "@material-ui/core/styles";
import { color, font_sizes, pxToRem } from "../../utilities/themes";
import { Text } from "../common/ui_kit";

/**
 * Header component for landing page which is displayed to the user before he sign in/ join in
 */

const useStyles = {
  headerGridStyle: {
    borderBottom: `1px solid ${color.primary_palette.border_opacity}`,
    height: "4.25rem",
  },
  joinUsBtnStyle: {
    width: "6.8125rem",
    height: "1.875rem",
    borderRadius: "9.8px",
    border: `2px solid ${color.primary_palette.highlight_purple}`,
    "&:hover": {
      border: `2px solid ${color.user_green}`,
      color: color.user_green,
    },
  },
  joinUsBtnTxtStyle: {
    fontSize: font_sizes.font_18,
    fontWeight: "600",
    lineHeight: "normal",
    color: color.primary_palette.franklin_purple,
    "&:hover": {
      color: color.user_green,
    },
  },
  loginTxtStyle: {
    fontSize: font_sizes.font_18,
    color: color.primary_palette.black,
    cursor: "pointer",
  },
  iconAlign: {
    marginLeft: "0.9375rem",
  },
  cursorPointer: {
    cursor: "pointer",
  },
  iconHighlight: {
    border: `3px solid ${color.primary_palette.highlight_purple}`,
    padding: pxToRem(2),
    borderRadius: pxToRem(10),
  },
};

class MobileLanding extends Component {
  render() {
    return (
      <>
        <Grid container>
          <Grid item xs={5}></Grid>
          <Grid item xs={2}>
            <Grid
              container
              item
              xs
              justify="center"
              alignItems="center"
              // className={classes.cursorPointer}
            >
              <img
                src="assets/images/trade-works.png"
                alt="Trade Works"
                style={{ marginTop: pxToRem(10) }}
              />
            </Grid>
          </Grid>
          <Grid item xs={5}></Grid>
        </Grid>
        <Grid container>
          <Grid item xs={12}>
            <Text
              family="gillsans_sb"
              size={18}
              color={color.primary_palette.franklin_purple}
              style={{
                marginTop: "100px",
                padding: "0 30px",
                textAlign: "center",
              }}
            >
              We are working on the Mobile App, but it is not ready yet! Please
              view TradeWorks in a web browser.
            </Text>
          </Grid>
        </Grid>
      </>
    );
  }
}

export default withRouter(
  withStyles(useStyles, { withTheme: true })(MobileLanding)
);
