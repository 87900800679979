import { withStyles } from "@material-ui/core/styles";
import tradework_theme, { pxToRem, color, font_sizes } from "../../../utilities/themes";

const styles = withStyles({
  recover_password_width: {
    width: pxToRem(411),
    border: `solid ${pxToRem(2)} ${color.primary_palette.franklin_purple}`,
    backgroundColor: color.primary_palette.fafa_gray,
    "&:focus": {
      outline: "none",
    },
  },
  demoModalWidth: {
    width: pxToRem(540),
    border: `solid ${pxToRem(2)} ${color.primary_palette.franklin_purple}`,
    backgroundColor: color.primary_palette.fafa_gray,
    "&:focus": {
      outline: "none",
    },
  },
  demoVideoModalWidth: {
    width: pxToRem(800),
    border: `solid ${pxToRem(2)} ${color.primary_palette.franklin_purple}`,
    backgroundColor: color.primary_palette.fafa_gray,
    "&:focus": {
      outline: "none",
    },
  },
  videoPlayer: {
    border: `${pxToRem(1)} solid`,
    margin: '0 auto'
  },
  recover_here_width: {
    width: pxToRem(469),
    border: `solid ${pxToRem(2)} ${color.primary_palette.franklin_purple}`,
    backgroundColor: color.primary_palette.fafa_gray,
    "&:focus": {
      outline: "none",
    },
  },
  forgot_email_img: {
    width: pxToRem(70),
    marginTop: pxToRem(40),
    marginBottom: pxToRem(15),
    marginLeft: pxToRem(40),
  },
  textCenter: {
    textAlign: "center",
  },
  password_width: {
    width: pxToRem(208),
    marginTop: pxToRem(19),
    "& .MuiFormLabel-root": {
      ...tradework_theme.typography.styles.gillsans_light,
      fontSize: pxToRem(18),
      marginTop: pxToRem(-5),
    },
    "& .MuiFormLabel-root.Mui-focused": {
      fontSize: pxToRem(10),
      ...tradework_theme.typography.styles.gillsans_r,
      color: color.primary_palette.franklin_purple,
    },
    "& .Mui-error": {
      color: color.primary_palette.christmas_red,
    },
    "& .MuiInputBase-input": {
      ...tradework_theme.typography.styles.gillsans_light,
      color: color.primary_palette.black,
    },
    "& .MuiInputLabel-shrink": {
      fontSize: pxToRem(10),
      ...tradework_theme.typography.styles.gillsans_r,
      color: color.primary_palette.franklin_purple,
      marginTop: pxToRem(9),
    },
    "& .MuiInputAdornment-root": {
      "&:focus": {
        outline: "none",
      },
    },
  },
  set_new_password: {
    width: pxToRem(179),
    height: pxToRem(30),
    borderRadius: pxToRem(20),
    border: `solid ${pxToRem(1)} ${color.form_colors.blueberry_purple}`,
    backgroundColor: color.primary_palette.white,
    fontSize: pxToRem(14),
    color: `${color.form_colors.royal_purple_1} !important`,
    ...tradework_theme.typography.styles.gillsans_sb,
    marginTop: pxToRem(15),
    marginBottom: pxToRem(39),
  },
  recover: {
    fontSize: pxToRem(14),
    color: color.primary_palette.franklin_purple,
    ...tradework_theme.typography.styles.gillsans_sb,
  },
  textRight: {
    textAlign: "right",
  },
  crossBtn: {
    minWidth: pxToRem(30),
    "& .MuiSvgIcon-root": {
      fontSize: pxToRem(14),
    },
  },
  char_num: {
    ...tradework_theme.typography.styles.Helvetica_Neue,
    fontWeight: 100,
  },

  //   success
  success_width: {
    width: pxToRem(389),
    border: `solid ${pxToRem(2)} ${color.primary_palette.franklin_purple}`,
    backgroundColor: color.primary_palette.white,
    "&:focus": {
      outline: "none",
    },
  },
  ribbon: {
    fontSize: pxToRem(86),
  },
  successCheck: {
    fontSize: pxToRem(60),
  },
  successModalPadding: {
    padding: pxToRem(20),
  },
  okBtn: {
    width: pxToRem(90),
    height: pxToRem(30),
    borderRadius: pxToRem(20),
    border: `solid ${pxToRem(1)} ${color.form_colors.blueberry_purple}`,
    backgroundColor: color.primary_palette.white,
    fontSize: pxToRem(14),
    color: `${color.form_colors.royal_purple_1} !important`,
    ...tradework_theme.typography.styles.gillsans_sb,
    marginTop: pxToRem(15),
    marginBottom: pxToRem(24),
  },

  // find ur password
  phn_email_blk: {
    width: pxToRem(208),
    marginTop: pxToRem(20),
    "& .MuiFormLabel-root": {
      ...tradework_theme.typography.styles.gillsans_light,
      fontSize: pxToRem(14),
    },
    "& .MuiFormLabel-root.Mui-focused": {
      fontSize: pxToRem(10),
      ...tradework_theme.typography.styles.gillsans_r,
      color: `${color.primary_palette.franklin_purple}`,
    },
    "& .Mui-error": {
      color: color.primary_palette.christmas_red,
      fontSize: pxToRem(10),
    },
    "& .MuiInputBase-input": {
      ...tradework_theme.typography.styles.gillsans_light,
      color: color.primary_palette.black,
      fontSize: pxToRem(18),
    },
    "& .MuiInputLabel-shrink": {
      fontSize: pxToRem(10),
      marginTop: pxToRem(9),
      ...tradework_theme.typography.styles.gillsans_r,
    },
  },
  locateBtn: {
    width: pxToRem(192),
    height: pxToRem(30),
    borderRadius: pxToRem(20),
    border: `solid ${pxToRem(1)} ${color.form_colors.blueberry_purple}`,
    backgroundColor: color.primary_palette.white,
    fontSize: pxToRem(14),
    color: `${color.form_colors.royal_purple_1} !important`,
    ...tradework_theme.typography.styles.gillsans_sb,
    marginTop: pxToRem(18),
    marginBottom: pxToRem(18),
  },
  sign_in: {
    width: "50%",
    flexGrow: 1,
    textAlign: "center",
  },
  join_here: {
    width: "30%",
    flexGrow: 1,
    textAlign: "center",
  },
  footer_spacing: {
    paddingTop: pxToRem(21),
    paddingBottom: pxToRem(40),
  },
  footer_recover_spacing: {
    paddingTop: pxToRem(21),
    paddingBottom: pxToRem(21),
  },
  get_new_password: {
    width: pxToRem(192),
    height: pxToRem(30),
    borderRadius: pxToRem(20),
    border: `solid ${pxToRem(1)} ${color.form_colors.blueberry_purple}`,
    backgroundColor: color.primary_palette.white,
    fontSize: pxToRem(14),
    color: `${color.form_colors.royal_purple_1} !important`,
    ...tradework_theme.typography.styles.gillsans_sb,
    marginTop: pxToRem(35),
    marginBottom: pxToRem(30),
  },
  spacingRadio: {
    paddingTop: pxToRem(24),
    width: pxToRem(208),
    margin: "0 auto",
  },
  radioBtn: {
    "& .MuiTypography-root": {
      fontSize: pxToRem(10),
      color: color.primary_palette.franklin_purple,
      textTransform: "capitalize",
    },
    "& .MuiSvgIcon-root": {
      fontSize: pxToRem(20),
    },
  },
  inline_block: {
    display: "inline-block",
  },

  // securityModal
  securityModal_width: {
    width: pxToRem(415),
    border: `solid ${pxToRem(2)} ${color.primary_palette.franklin_purple}`,
    backgroundColor: color.primary_palette.fafa_gray,
    padding: `${pxToRem(0)} ${pxToRem(38)}`,
    "&:focus": {
      outline: "none",
    },
  },
  text_message_spacing: {
    paddingTop: pxToRem(29),
    paddingBottom: pxToRem(40),
  },
  security_block: {
    width: pxToRem(47),
    height: pxToRem(58),
    border: `solid ${pxToRem(2)} ${color.secondary_palette.grays.shadow_gray}`,
    backgroundColor: color.primary_palette.white,
    "& .MuiInput-root": {
      marginTop: pxToRem(26),
    },
  },
  justify_evenly: {
    justifyContent: "space-evenly",
  },
  get_code_spacing: {
    paddingTop: pxToRem(64),
    paddingBottom: pxToRem(50),
  },
  lock_img: {
    width: pxToRem(81),
    height: pxToRem(71),
    paddingTop: pxToRem(50),
    paddingBottom: pxToRem(28),
  },
  margin_top_25: {
    marginTop: pxToRem(-25),
  },
  lock_img_new: {
    width: pxToRem(81),
    height: pxToRem(71),
    paddingBottom: pxToRem(15),
  },
  cursor: {
    cursor: "pointer",
  },
  inpErr: {
    "& .MuiInputLabel-shrink": {
      color: color.primary_palette.christmas_red,
    },
  },
  margin_top_20: {
    marginTop: pxToRem(20),
  },
  spacing_padding: {
    padding: `${pxToRem(0)} ${pxToRem(28)}`,
  },
  closeAlign: {
    fontSize: pxToRem(12),
    float: "right",
    margin: pxToRem(16),
    cursor: "pointer",
  },
  password_validation: {
    position: "relative",
    // top: "-0.5625rem",
    // left: "1.4375rem",
    fontSize: font_sizes.font_12,
    color: color.form_colors.textfield_color + "!important",
    fontFamily: "avenir_light",
  },
  errorText: {
    position: "relative",
    // top: "-0.5625rem",
    // left: "1.4375rem",
    fontSize: font_sizes.font_12,
    color: color.primary_palette.christmas_red + "!important",
    fontFamily: "avenir_bold",
  },
  successText: {
    position: "relative",
    // top: "-0.5625rem",
    // left: "1.4375rem",
    fontSize: font_sizes.font_12,
    color: color.form_colors.sucess_color + "!important",
    fontFamily: "avenir_bold",
  },
  arrow_icon: {
    width: pxToRem(10),
    height: pxToRem(10),
    marginRight: pxToRem(10),
    marginTop: pxToRem(8),
  },
  headerText: {
    textDecoration: "underline",
    marginBottom: pxToRem(10),
  },
  optionsAlign: {
    color: color.primary_palette.franklin_purple,
    cursor: "pointer",
    lineHeight: '1.5',
    "&:hover": {
      color: color.primary_palette.pine_green
    }
  }
});

export default styles;
