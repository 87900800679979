import { withStyles } from "@material-ui/core/styles";
import tradework_theme, {
  color,
  pxToRem,
  font_sizes,
  px_to_rem_sizes,
} from "../../utilities/themes";

const styles = withStyles({
  popup: {
    backgroundColor: color.secondary_palette.grays.fafa_gray,
    padding: "1.125rem",
    border: `2px solid ${color.primary_palette.franklin_purple}`,
    marginTop: "70px",
    width: "389px",
    margin: "0 auto",
    position: "relative",
    "&:focus": {
      border: `2px solid ${color.primary_palette.franklin_purple}`,
      outline: "none",
    },
  },
  rememberMe: {
    marginTop: "-14px",
    "& .MuiIconButton-root": {
      paddingRight: 0,
    },
  },
  rememberMeText: {
    fontSize: "15px !important",
    fontWeight: "600",
    fontFamily: "gillsans_light",
    "& .MuiFormControlLabel-label": {
      fontFamily: "gillsans_r",
    },
    "& .MuiIconButton-label": {
      marginTop: "1px",
    },
  },
  closeBtn: {
    padding: 0,
    minWidth: "20px",
    "& .MuiButton-label": {
      "& svg": {
        fontSize: "1rem",
      },
    },
  },
  formContainer: {
    paddingTop: "18px",
    // paddingLeft: "4.8125rem",
    // paddingRight: "4.8125rem"
  },
  submit_button: {
    borderRadius: "20px",
    border: `0.08125rem solid ${color.form_colors.blueberry_purple}`,
    backgroundColor: color.primary_palette.white,
    fontSize: "14px",
    padding: "0 21px",
    width: "160px",
    height: "30px",
    marginTop: "31px",
    marginBottom: "32px",
    fontFamily: "gillsans_sb",
    color: color.form_colors.royal_purple_1,
    "&:hover": {
      backgroundColor: color.button_hover,
      background: "none",
      color: `${color.primary_palette.franklin_purple} !important`,
      border: `0.08125rem solid ` + color.primary_palette.franklin_purple,
    },
    "& .MuiButton-endIcon": {
      top: "8px !important",
      right: "-23px !important",
    },
  },
  auto_email: {
    color: color.primary_palette.christmas_red,
    position: "relative",
    top: "14px",
    "& .MuiFormControlLabel-label": {
      fontSize: "10px",
      color: color.primary_palette.christmas_red,
      fontFamily: "gillsans_r",
    },
    "& .MuiFormControlLabel-root": {
      marginLeft: 0,
    },
    "& .MuiInputLabel-root": {
      fontSize: "0.625em",
      color: color.primary_palette.black,
      marginLeft: "2px",
      display: "inline-block",
      fontFamily: "NeutraText",
    },
  },
  errorText: {
    position: "relative",
    top: pxToRem(2),
    fontSize: font_sizes.font_12,
    color: color.primary_palette.christmas_red,
    textAlign: "left",
  },
  successText: {
    position: "relative",
    top: "-0.5625rem",
    fontSize: font_sizes.font_12,
  },
  textfieldSpark: {
    "& .MuiInput-underline:hover:after": {
      borderBottomColor: color.primary_palette.highlight_purple,
      transform: "scaleX(100)",
    },
    "& .MuiInput-underline:hover:before": {
      borderBottom: "0.5px solid rgba(0, 0, 0, 0.87)",
    },
  },
  sucess_underline: {
    "& .MuiInput-underline:before": {},
  },
  footerContainer: {
    textAlign: "center",
    fontSize: "16.4px",
    color: color.form_colors.royal_purple_1,
    fontFamily: "gillsans_sb",
    "& .MuiTypography-colorError": {
      color: `${color.primary_palette.christmas_red} !important`,
    },
    "& .MuiTypography-colorPrimary": {
      color: color.form_colors.royal_purple_1,
    },
  },
  footer_spacing: {
    padding: "0 16px",
    paddingBottom: "30px",
  },
  cursor: {
    cursor: "pointer",
  },
  hideField: {
    display: "none",
  },
  signUpErrorMessage: {
    position: "absolute",
    top: pxToRem(194),
    left: pxToRem(67),
    fontSize: "10px",
    color: color.primary_palette.christmas_red,
  },
  forgotPasswordLarge: {
    color: color.primary_palette.franklin_purple,
    fontSize: pxToRem(16),
    textDecoration: "underline",
    fontWeight: 600,
    fontFamily: "gillsans_r",
    textTransform: "uppercase",
    marginTop: pxToRem(39),
  },
  inp_error: {
    "& .MuiInput-underline:before": {
      borderBottomColor: `${color.primary_palette.christmas_red}`,
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: `${color.primary_palette.christmas_red}`,
    },
    "& .Mui-focused": {
      fontSize: font_sizes.font_14,
      color: `${color.primary_palette.christmas_red}`,
    },
    "& .MuiFormLabel-root": {
      color: `${color.primary_palette.christmas_red}  !important`,
    },
    "& .MuiInputBase-input": {
      color: color.primary_palette.black,
    },
    "& .MuiInputLabel-shrink": {
      color: `${color.primary_palette.christmas_red} !important`,
    },
  },
  inp_success: {
    "& .MuiInput-underline:before": {
      borderBottomColor: `${color.form_colors.army_green}`,
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: `${color.form_colors.army_green}`,
    },
    "& .Mui-focused": {
      fontSize: font_sizes.font_14,
      color: `${color.primary_palette.franklin_purple}`,
    },
    "& .MuiFormLabel-root": {
      color: `${color.primary_palette.franklin_purple}`,
    },
    "& .MuiInputBase-input": {
      color: color.primary_palette.black,
    },
    "& .MuiInputLabel-shrink": {
      color: `${color.primary_palette.pine_green} !important`,
    },
  },
  inp_initial: {
    "& .MuiInput-underline:before": {
      borderBottomColor: color.primary_palette.highlight_purple,
    },
    "& .MuiInputLabel-formControl": {
      color: color.form_colors.textfield_color,
      top: "0px" + "!important",
    },
    "& .MuiInputLabel-shrink": {
      color: `${color.primary_palette.franklin_purple} !important`,
      fontSize: "12px",
      fontFamily: "gillsans_r",
    },
  },
  textFieldSuccess: {
    fontSize: font_sizes.font_18,
    color: color.form_colors.textfield_color,
    width: "300px",
    marginBottom: "12px",
    fontFamily: "gillsans_light",
    "& .MuiFormLabel-root.Mui-focused": {
      fontSize: "10px",
    },
    "& .MuiFormHelperText-root.Mui-error": {
      margin: "0",
      position: "relative",
      top: "0.25rem",
    },
    "& .MuiInputBase-input": {
      fontFamily: "NeutraText",
      fontSize: "18px",
      padding: "6px 0px 4px 0px !important",
    },

    "& .MuiFormLabel-root": {
      color: color.form_colors.textfield_color,
      fontSize: "18px",
      fontFamily: "gillsans_light",
    },
    "& .MuiInputLabel-shrink": {
      fontFamily: "gillsans_r",
      fontSize: "10px",
      marginTop: "9px",
    },
    "& .MuiInputAdornment-root": {
      "& .MuiSvgIcon-root": {
        marginTop: pxToRem(8),
      },
      "&:focus": {
        outline: "none !important",
      },
      "& span": {
        "&:focus": {
          outline: "none !important",
        },
      },
    },
  },
});
export default styles;
