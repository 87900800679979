import React, { useState } from "react";
import newPasswordStyles from "../../pages/password/styles/newPasswordStyles";
import Text from "../../components/common/ui_kit/text";
import Row from "../../components/common/ui_kit/row";
import CustomModal from "../../components/inputs/custom_modal";
import { color } from "../../utilities/themes";
import Close_Icon from "../../components/data_display/icons/Close";
import VideoDisplayModal from "./VideoDisplayModal";

function VideoBotDemoModal(props) {
    const { classes, onClose, openDemoVideoModal } = props;
    const [openVideoModal, setOpenVideoModal] = useState(false)
    const [selectedVideoUrl, setSelectedVideoUrl] = useState(false)
    const [selectedModalTitle, setSelectedModalTitle] = useState("")

    const toggleVideoModal = (url, modalTitle) => () => {
        setOpenVideoModal(!openVideoModal)
        setSelectedVideoUrl(url)
        setSelectedModalTitle(modalTitle)
    }

    return (
        <>
            <CustomModal
                open={openDemoVideoModal}
                aria-labelledby="modal-title"
                disablePortal
                aria-describedby="modal-description"
                disableAutoFocus
            >
                <div className={classes.demoModalWidth}>
                    <div>
                        <Close_Icon
                            onClick={onClose}
                            className={classes.closeAlign}
                        />
                    </div>

                    <div className={classes.textCenter}>
                        <img
                            src="/assets/images/Annie.png"
                            className={classes.forgot_email_img}
                        />
                    </div>
                    <Text
                        family="gillsans_sb"
                        size={22}
                        className={classes.textCenter}
                    >
                        ANNIE VideoBot DEMOS
                    </Text>
                    <div style={{ padding: "20px" }}>
                        <div style={{ marginTop: "20px" }}>
                            <Row>
                                <img
                                    src="/assets/images/arrow-icon.png"
                                    className={classes.arrow_icon}
                                />
                                <Text
                                    family="gillsans_r"
                                    size={18}
                                    className={classes.optionsAlign}
                                    onClick={toggleVideoModal("https://player.vimeo.com/video/1054387604?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479", 'FULL VideoBot Demo')}
                                >
                                    See the <b>FULL</b> VideoBot Demo, 11 min
                                </Text>
                            </Row>
                        </div>
                        <div style={{ marginTop: "20px" }}>
                            <Row>
                                <Text
                                    family="gillsans_r"
                                    size={18}
                                    color={color.primary_palette.franklin_purple}
                                    style={{ marginBottom: '10px' }}
                                >
                                    View <b>Specific Sections</b> of the VideoBot Demo
                                </Text>
                            </Row>
                            <Text
                                family="gillsans_sb"
                                size={18}
                                className={classes.headerText}
                            >
                                COMPANY SIDE
                            </Text>
                            <Row>
                                <img
                                    src="/assets/images/arrow-icon.png"
                                    className={classes.arrow_icon}
                                />
                                <Text
                                    family="gillsans_r"
                                    size={18}
                                    className={classes.optionsAlign}
                                    onClick={toggleVideoModal('https://player.vimeo.com/video/1054387464?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479', 'Create an ANNIE VideoBot Interview')}
                                >
                                    Create an ANNIE VideoBot Interview, 5 min
                                </Text>
                            </Row>
                            <Row>
                                <img
                                    src="/assets/images/arrow-icon.png"
                                    className={classes.arrow_icon}
                                />
                                <Text
                                    family="gillsans_r"
                                    size={18}
                                    className={classes.optionsAlign}
                                    onClick={toggleVideoModal('https://player.vimeo.com/video/1054387421?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479', 'Invite an Applicant to take an ANNIE')}

                                >
                                    Invite an Applicant to take an ANNIE, 2 min
                                </Text>
                            </Row>
                            <Row>
                                <img
                                    src="/assets/images/arrow-icon.png"
                                    className={classes.arrow_icon}
                                />
                                <Text
                                    family="gillsans_r"
                                    size={18}
                                    className={classes.optionsAlign}
                                    onClick={toggleVideoModal('https://player.vimeo.com/video/1054387376?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479', "Review the Applicant's Interview Responses")}

                                >
                                    Review the Applicant's Interview Responses, 2 min
                                </Text>
                            </Row>
                            <Row>
                                <img
                                    src="/assets/images/arrow-icon.png"
                                    className={classes.arrow_icon}
                                />
                                <Text
                                    family="gillsans_r"
                                    size={18}
                                    className={classes.optionsAlign}
                                    onClick={toggleVideoModal('https://player.vimeo.com/video/1054398870?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479', "Add or Edit an ANNIE on an Existing Job Post")}
                                >
                                    Add or Edit an ANNIE on an Existing Job Post, 2 min
                                </Text>
                            </Row>
                        </div>
                        <div style={{ marginTop: "20px" }}>
                            <Text
                                family="gillsans_sb"
                                size={18}
                                className={classes.headerText}
                            >
                                APPLICANT SIDE
                            </Text>
                            <Row>
                                <img
                                    src="/assets/images/arrow-icon.png"
                                    className={classes.arrow_icon}
                                />
                                <Text
                                    family="gillsans_r"
                                    size={18}
                                    className={classes.optionsAlign}
                                    onClick={toggleVideoModal('https://player.vimeo.com/video/1054398921?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479', "What Taking an ANNIE Interview Looks Like")}
                                >
                                    What Taking an ANNIE Interview Looks Like, 3 min
                                </Text>
                            </Row>
                        </div>
                    </div>
                </div>
            </CustomModal >
            {openVideoModal && (
                <VideoDisplayModal onClose={toggleVideoModal()} openDemoVideoModal={openVideoModal} videoUrl={selectedVideoUrl} modalTitle={selectedModalTitle} />
            )
            }
        </>
    );
}

export default newPasswordStyles(VideoBotDemoModal);
