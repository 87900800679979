import React, { useState } from "react";
import newPasswordStyles from "../../pages/password/styles/newPasswordStyles";
import Text from "../../components/common/ui_kit/text";
import Row from "../../components/common/ui_kit/row";
import CustomModal from "../../components/inputs/custom_modal";
import { color, pxToRem } from "../../utilities/themes";
import Close_Icon from "../../components/data_display/icons/Close";
import VideoDisplayModal from "./VideoDisplayModal";

function DemoModal(props) {
    const { classes, onClose, openDemoVideoModal } = props;
    const [openVideoModal, setOpenVideoModal] = useState(false)
    const [selectedVideoUrl, setSelectedVideoUrl] = useState(false)
    const [selectedModalTitle, setSelectedModalTitle] = useState("")

    const toggleVideoModal = (url, modalTitle) => () => {
        setOpenVideoModal(!openVideoModal)
        setSelectedVideoUrl(url)
        setSelectedModalTitle(modalTitle)
    }

    return (
        <>
            <CustomModal
                open={openDemoVideoModal}
                aria-labelledby="modal-title"
                disablePortal
                aria-describedby="modal-description"
                disableAutoFocus
            >
                <div className={classes.demoModalWidth}>
                    <div>
                        <Close_Icon
                            onClick={onClose}
                            className={classes.closeAlign}
                        />
                    </div>

                    <div className={classes.textCenter}>
                        <img
                            src="/assets/images/Demo Icon 2.png"
                            className={classes.forgot_email_img}
                        />
                    </div>
                    <Text
                        family="gillsans_sb"
                        size={22}
                        className={classes.textCenter}
                    >
                        Build Your Personal Online Profile
                    </Text>
                    <div style={{ padding: "20px" }}>
                        <div>
                            <Row>
                                <img
                                    src="/assets/images/arrow-icon.png"
                                    className={classes.arrow_icon}
                                />
                                <Text
                                    family="gillsans_r"
                                    size={18}
                                    className={classes.optionsAlign}
                                    onClick={toggleVideoModal("https://player.vimeo.com/video/1054386737?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479", 'How to Build Your Personal Profile (Short)')}
                                >
                                    Build Your Profile in 4 Minutes!
                                </Text>
                            </Row>
                            <Row>
                                <img
                                    src="/assets/images/arrow-icon.png"
                                    className={classes.arrow_icon}
                                />
                                <Text
                                    family="gillsans_r"
                                    size={18}
                                    className={classes.optionsAlign}
                                    onClick={toggleVideoModal("https://player.vimeo.com/video/1054386335?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479", 'Build Your Profile with More Detail')}
                                >
                                    Build Your Profile with More Detail, 24 min
                                </Text>
                            </Row>
                            <Text
                                family="gillsans_r"
                                size={16}
                                style={{ paddingLeft: "20px" }}
                            >
                                Better for Employers to Find you!
                            </Text>
                            <Text
                                family="gillsans_sb"
                                size={18}
                                className={classes.headerText}
                                style={{ marginTop: pxToRem(20) }}
                            >
                                Other Important Sections of the Personal Profile
                            </Text>
                            <Row>
                                <img
                                    src="/assets/images/arrow-icon.png"
                                    className={classes.arrow_icon}
                                />
                                <Text
                                    family="gillsans_r"
                                    size={18}
                                    className={classes.optionsAlign}
                                    onClick={toggleVideoModal("https://player.vimeo.com/video/1055680868?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479", 'What the Recommendation Request MadLib looks like to the Letter Writer')}
                                >
                                    What the Recommendation Request MadLib looks like to the Letter Writer, 3 min
                                </Text>
                            </Row>
                            <Row>
                                <img
                                    src="/assets/images/arrow-icon.png"
                                    className={classes.arrow_icon}
                                />
                                <Text
                                    family="gillsans_r"
                                    size={18}
                                    className={classes.optionsAlign}
                                    onClick={toggleVideoModal("https://player.vimeo.com/video/1054387265?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479", 'Additional Great Features to Add in Your Personal Profile')}
                                >
                                    Additional Great Features to Add in Your Personal Profile, 9 min
                                </Text>
                            </Row>
                            <Row>
                                {/* <img
                                    src="/assets/images/arrow-icon.png"
                                    className={classes.arrow_icon}
                                /> */}
                                <Text
                                    family="gillsans_sb"
                                    size={18}
                                    className={classes.headerText}
                                    style={{ marginTop: pxToRem(20) }}
                                >
                                    Other Super Intresting Features (Mo'Better)
                                </Text>
                            </Row>
                            <Row>
                                <img
                                    src="/assets/images/arrow-icon.png"
                                    className={classes.arrow_icon}
                                />
                                <Text
                                    family="gillsans_r"
                                    size={18}
                                    className={classes.optionsAlign}
                                    onClick={toggleVideoModal("https://player.vimeo.com/video/1054386979?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479", 'My Inventory')}
                                >
                                    My Inventory: Resume, Elevator Pitch, PDF Portfolio, 3 min
                                </Text>
                            </Row>
                            <Text
                                family="gillsans_r"
                                size={16}
                                style={{ paddingLeft: "20px" }}
                            >
                                Essential for Job Applications!
                            </Text>
                            <Row>
                                <img
                                    src="/assets/images/arrow-icon.png"
                                    className={classes.arrow_icon}
                                />
                                <Text
                                    family="gillsans_r"
                                    size={18}
                                    className={classes.optionsAlign}
                                    onClick={toggleVideoModal("https://player.vimeo.com/video/1054387086?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479", 'Share Your Profile')}
                                >
                                    Share Your Profile, 10 min
                                </Text>
                            </Row>
                            <Row>
                                <img
                                    src="/assets/images/arrow-icon.png"
                                    className={classes.arrow_icon}
                                />
                                <Text
                                    family="gillsans_r"
                                    size={18}
                                    className={classes.optionsAlign}
                                    onClick={toggleVideoModal("https://player.vimeo.com/video/1054387021?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479", 'Invite a Friend to Join')}
                                >
                                    Invite a Friend to Join, 2 min
                                </Text>
                            </Row>
                            <Row>
                                <img
                                    src="/assets/images/arrow-icon.png"
                                    className={classes.arrow_icon}
                                />
                                <Text
                                    family="gillsans_r"
                                    size={18}
                                    className={classes.optionsAlign}
                                    onClick={toggleVideoModal("https://player.vimeo.com/video/1054387050?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479", 'Grit Test')}
                                >
                                    Grit Test, 1 min
                                </Text>
                            </Row>
                        </div>
                    </div>
                </div>
            </CustomModal>
            {openVideoModal && (
                <VideoDisplayModal onClose={toggleVideoModal()} openDemoVideoModal={openVideoModal} videoUrl={selectedVideoUrl} modalTitle={selectedModalTitle} />
            )
            }
        </>
    );
}

export default newPasswordStyles(DemoModal);
