import React, { Component } from "react";
import Grid from "@material-ui/core/Grid";
import { withStyles } from "@material-ui/core/styles";
import SvgBuilding from "../data_display/icons/Building";
import SvgUserIcon from "../data_display/icons/UserIcon";
import Chevron_Down_Icon from "../data_display/icons/Arrow_Chevron_Down";
import CustomTextField from "../inputs/custom_textfields";
import CustomCheckbox from "../inputs/custom_checkbox";
import CustomButton from "../navigations/custom_buttons";
import tradework_theme, {
  color,
  font_sizes,
  pxToRem,
} from "../../utilities/themes";
import strings from "../../utilities/strings";
import { BASEURL } from "../../constants";
import Close_Icon from "../data_display/icons/Close";
import zIndex from "@material-ui/core/styles/zIndex";
import FeedbackModal from "./FeedbackModal";
import { LoginActions } from "../../redux/actions";
import { connect } from "react-redux";
import { MenuItem } from "@material-ui/core";
import _ from "lodash";
import CustomScrollbars from "../common/ui_kit/custom_scrollbar";
import CustomAutocomplete from "../inputs/custom_auto_complete";
import { Row, Text } from "../common/ui_kit";
import DemoModal from "./DemoModal";

/**
 * Content component for landing page which is displayed to the user before he sign in/ join in
 */

const useStyles = {
  welcomeTxtStyle: {
    fontFamily: "NeutraText",
    fontSize: font_sizes.font_35,
    textAlign: "center",
    color: color.primary_palette.franklin_purple,
    marginTop: "21px",
    marginBottom: "30px",
  },
  forgot_email_img: {
    width: pxToRem(30),
    marginRight: pxToRem(10),
  },
  subTxtStyle: {
    fontSize: font_sizes.font_35,
    color: color.primary_palette.franklin_purple,
    lineHeight: "37px",
    position: "relative",
    top: "16px",
  },
  subHeadTxtStyle: {
    fontSize: font_sizes.font_18,
    fontWeight: 300,
    textAlign: "center",
    color: color.primary_palette.black,
  },
  Close_icon: {
    position: "absolute",
    fontSize: pxToRem(10),
    right: "34px",
    top: "10px",
    zIndex: 9,
    cursor: "pointer",
    "& .MuiSvgIcon-root": {
      color: color.primary_palette.black,
    },
  },
  arrow_icon: {
    // width: pxToRem(19),
    height: pxToRem(26),
    position: "absolute",
    right: pxToRem(4),
    zIndex: 9,
    top: pxToRem(2),
    cursor: "pointer",
  },
  addImageIcon: {
    backgroundImage: `url("assets/icons/icon_happy_arrow.svg")`,
    backgroundSize: "contain",
    height: pxToRem(26),
    width: pxToRem(26),
    position: "absolute",
    right: pxToRem(4),
    zIndex: 9,
    top: pxToRem(2),
    backgroundRepeat: "no-repeat",
    cursor: "pointer",
    "&:hover": {
      backgroundImage: `url("assets/icons/icon_happy_arrow-green.svg")`,
      backgroundSize: "contain",
      height: pxToRem(26),
      width: pxToRem(26),
      position: "absolute",
      right: pxToRem(4),
      zIndex: 9,
      top: pxToRem(2),
      backgroundRepeat: "no-repeat",
      cursor: "pointer",
    },
  },
  subGridAlign: {
    width: "34.6875rem",
    height: "3.0625rem",
    borderRadius: "3px",
    boxShadow: "2px 2px 4px 0 rgba(0, 0, 0, 0.31)",
    border: `1px solid` + color.secondary_palette.grays.light_gray,
    backgroundColor: "#fff",
    marginTop: "-5px",
  },
  btnGridStyle: {
    backgroundColor: color.secondary_palette.grays.bg_gray,
    height: "3.9375rem",
  },
  btnGridStyle1: {
    backgroundColor: color.secondary_palette.grays.bg_gray,
    height: "3.9375rem",
    borderRight: `2px solid` + color.primary_palette.white,
  },
  btnTxtStyle: {
    fontSize: font_sizes.font_12_3,
    fontWeight: "600",
    color: `${color.primary_palette.franklin_purple} !important`,
    border: `1.4px solid` + color.primary_palette.franklin_purple,
    fontFamily: "gillsans_r",
    borderRadius: "20px",
    maxWidth: "164px",
  },
  gridRightBorder: {
    borderRight: `1px solid` + color.secondary_palette.grays.light_gray,
  },
  subContentStyle: {
    fontSize: font_sizes.font_22,
    color: color.secondary_palette.grays.ping_gray,
    marginTop: "2.1875em",
  },
  inputLabelTxt: {
    fontSize: font_sizes.font_14,
    lineHeight: "1.79",
    color: color.primary_palette.franklin_purple,
  },
  chkbxLabelStyle: {
    fontSize: font_sizes.font_12,
    color: color.primary_palette.black,
  },
  pilerAlign: {
    width: "1.625rem",
    height: "1.875rem",
  },
  testLogoAlign: {
    position: "absolute",
    width: "130px",
    height: "130px",
    right: "17px",
    top: "57px",
    "& img": {
      width: "120px",
      height: "120px",
      margin: "0 auto",
    },
  },
  buildingAlign: {
    marginLeft: "6rem",
    paddingBottom: "4px",
  },
  your_location: {
    width: pxToRem(200),
    textAlign: "center",
    "& .MuiInputBase-input": {
      paddingTop: "10px !important",
      paddingBottom: "0px !important",
    },
    "&::placeholder": {
      textAlign: "center",
    },
    "& svg": {
      width: "16px",
      height: "16px",
      position: "absolute",
      top: "0px !important",
      left: "-11px",
    },
  },
  your_location1: {
    width: pxToRem(200),
    textAlign: "right",
    "& .MuiInputBase-input": {
      paddingTop: "10px !important",
      paddingBottom: "0px !important",
    },
    "&::placeholder": {
      textAlign: "center",
    },
    "& svg": {
      width: "16px",
      height: "16px",
      position: "absolute",
      top: "0px !important",
      left: "-11px",
    },
  },
  userIconAlign: {
    marginRight: "3.625rem",
    paddingBottom: "4px",
  },
  subGridInput: {
    width: "100%",
    borderRadius: "3px",
    boxShadow: "2px 2px 4px 0 rgba(0, 0, 0, 0.31)",
    marginTop: "-6px",
    "& .MuiOutlinedInput-input": {
      textAlign: "left",
      padding: "12px 52px 10px 16px",
      fontSize: pxToRem(18),
      textTransform: "uppercase",
      ...tradework_theme.typography.styles.gillsans_r,
      "&::placeholder": {
        color: color.primary_palette.black,
        ...tradework_theme.typography.styles.gillsans_light,
        textTransform: "none",
      },
    },
  },
  subGridInput1: {
    width: "100%",
    borderRadius: "3px",
    boxShadow: "2px 2px 4px 0 rgba(0, 0, 0, 0.31)",
    marginTop: "-6px",
    "& .MuiOutlinedInput-input": {
      textAlign: "right",
      padding: "12px 52px 10px 16px",
      fontSize: pxToRem(18),
      textTransform: "uppercase",
      ...tradework_theme.typography.styles.gillsans_r,
      "&::placeholder": {
        color: color.primary_palette.black,
        ...tradework_theme.typography.styles.gillsans_light,
        textTransform: "none",
        // textAlign: "left",
      },
    },
  },
  hoverTxtStyle: {
    color: color.primary_palette.franklin_purple,
    cursor: "pointer",
    "&:hover": {
      "& p": {
        color: color.primary_palette.pine_green,
      },
    },
  },
  rowHoverTxtStyle: {
    color: color.primary_palette.franklin_purple,
    cursor: "pointer",
    "&:hover": {
      color: color.primary_palette.pine_green,
      "& p": {
        color: color.primary_palette.pine_green,
      },
    },
  },
  "MuiInputBase-input": {
    textAlign: "center",
  },
  btnAlign: {
    textAlign: "left",
  },
  right: {
    textAlign: "right",
  },
  border: {
    borderLeft: `1px solid` + color.secondary_palette.grays.light_gray,
  },
  checkbox: {
    "& .MuiSvgIcon-root": {
      width: "17px",
      height: "17px",
    },
  },
  btnPost_resume: {
    width: "164px",
    height: "30px",
    backgroundColor: color.primary_palette.white,
  },
  btnPost_job: {
    width: "120px",
    height: "30px",
    backgroundColor: color.primary_palette.white,
  },
  betaImageIcon: {
    backgroundImage: `url("assets/images/Lil-beta-stamp.png")`,
    backgroundSize: "contain",
    cursor: "pointer",
    width: "400px",
    backgroundRepeat: "no-repeat",
    cursor: "pointer",
    "&:hover": {
      backgroundImage: `url("assets/images/Stamp (Black).png")`,
      backgroundSize: "contain",
      width: "400px",
    },
  },
};

class LandingContent extends Component {
  constructor(props) {
    super(props);
    this.rapInput = "";
    this.state = {
      jobTitle: "",
      location: "",
      companyLocation: "",
      openFeedBackModal: false,
      openDemoModal: false,
    };
  }

  // redirection to job posts
  redirectToJpack = (titleVal, locationVal) => {
    const { jobTitle, location } = this.state;
    // console.log("location", location);
    // return;
    let route = "";
    route = `${BASEURL.URL}jpack/public/jobPostings`;
    window.location.href = `${route}/${jobTitle || titleVal || null}/${
      location?.cityAndState || locationVal || null
    }`;
  };

  toggleFeedBackModal = () => {
    localStorage.setItem("openFeedbaack", false);
    this.setState({ openFeedBackModal: !this.state.openFeedBackModal });
  };

  toggleDemoModal = () => {
    this.setState({ openDemoModal: !this.state.openDemoModal });
  };

  // redirection to job posts
  redirectToCATS = (titleVal, locationVal) => {
    const { companyLocation } = this.state;
    let route = "";
    route = `${BASEURL.URL}cats/public/DiscoverSearch`;
    window.location.href = `${route}/${null}/${
      companyLocation?.cityAndState || null
    }/${titleVal}`;
  };

  onLocationChange = (location) => () => {
    if (!location) {
      return;
    }
    this.setState({
      ...this.state,
      location: location,
    });
  };

  onLocationChangeCompany = (location) => () => {
    if (!location) {
      return;
    }
    this.setState({
      ...this.state,
      companyLocation: location,
    });
  };

  componentDidMount() {
    this.props.dispatch(LoginActions.tradeLocations({ name: "" }));
  }

  handleInputChange = (e) => {
    const { name, value } = e.target;
    // console.log(name, "name", value);
    // if (name === "jobTitle") {
    //   if (value) {
    //     this.redirectToJpack(value, null);
    //   }
    // }
    // if (name === "location") {
    //   if (value) {
    //     this.redirectToJpack(null, value);
    //   }
    // }
    // if (name === "comunityTitle") {
    //   if (value) {
    //     this.redirectToCATS(value, null);
    //   }
    // }
    this.setState({ [name]: value });
  };

  clearSearch = (name) => () => {
    this.setState({ [name]: "" });
  };

  jobPostSearch = () => {
    this.redirectToJpack(this.state.jobTitle, null);
  };

  handleInput = (evt) => {
    this.rapInput = evt.target.value;
    this.handleGetRapData();
  };

  handleGetRapData = () => {
    // alert("1")
    // if (this.rapInput.length >= 2) {
    this.props.dispatch(LoginActions.tradeLocations({ name: this.rapInput }));
    // }
  };

  communityPostSearch = () => {
    this.redirectToCATS(this.state.comunityTitle, null);
  };

  handleKeypress = (event) => {
    const { value } = event.target;
    if (event.key === "Enter") {
      this.redirectToJpack(value, null);
    }
  };

  handleKeypressCommunity = (event) => {
    const { value } = event.target;
    if (event.key === "Enter") {
      this.redirectToCATS(value, null);
    }
  };

  render() {
    const { classes, openLoginModal, topLocations } = this.props;
    const { openFeedBackModal, location, companyLocation, openDemoModal } =
      this.state;
    return (
      <>
        <Grid container>
          <Grid item md={12} xs={12}>
            <Grid
              container
              justify="flex-end"
              xs={12}
              md={12}
              className={classes.testLogoAlign}
            >
              {!openFeedBackModal && (
                // <img
                //   src="assets/images/Lil-beta-stamp.png"
                //   alt={strings.landing_page.titles.feedback_logo_text}
                //   onClick={this.toggleFeedBackModal}
                //   style={{ cursor: "pointer" }}
                // />

                <div
                  className={classes.betaImageIcon}
                  // src={image || this.defaultImage}
                  onClick={this.toggleFeedBackModal}
                />
              )}
            </Grid>
            <Grid container xs justify="center">
              <Grid
                item
                container
                xs={12}
                md={12}
                justify="center"
                className={classes.welcomeTxtStyle}
              >
                {strings.landing_page.titles.welcome}
                <br />
                {strings.landing_page.titles.connect_with_community}
              </Grid>
            </Grid>
          </Grid>
          <Grid container>
            <Grid container item md={6} xs={6} alignItems="center">
              <Grid
                container
                item
                md={2}
                xs={4}
                justify="center"
                alignItems="center"
                className={classes.buildingAlign}
              >
                <SvgBuilding />
              </Grid>
              <Grid
                container
                item
                md={8}
                xs={6}
                justify="flex-start"
                alignItems="center"
              >
                <Grid item className={classes.subTxtStyle}>
                  {strings.landing_page.titles.get_found}
                </Grid>
              </Grid>
              <Grid container justify="center">
                <Grid
                  container
                  item
                  md={8}
                  xs={11}
                  justify="center"
                  alignItems="center"
                  style={{ position: "relative" }}
                >
                  {/* <img
                    src="assets/icons/icon_happy_arrow.svg"
                    className={classes.arrow_icon}
                    alt=""
                    // onClick={jobPostSearch}
                    onMouseUp={this.jobPostSearch}
                  /> */}
                  <div
                    className={classes.addImageIcon}
                    onClick={this.jobPostSearch}
                  />
                  <Close_Icon
                    className={classes.Close_icon}
                    onMouseUp={this.clearSearch("jobTitle")}
                  />
                  <CustomTextField
                    variant="outlined"
                    className={classes.subGridInput}
                    placeholder={strings.landing_page.titles.job_title}
                    onBlur={this.handleInputChange}
                    onKeyPress={this.handleKeypress}
                    name="jobTitle"
                    key={this.state.jobTitle}
                    defaultValue={this.state.jobTitle}
                  />
                </Grid>
                <Grid container item md={8} xs={11} alignItems="center">
                  <Grid item container md={12} xs={12}>
                    {/* <CustomTextField
                      onBlur={this.handleInputChange}
                      name="jobTitle"
                      defaultValue={this.state.jobTitle}
                    /> */}
                    <CustomAutocomplete
                      className={classes.your_location}
                      placeholder="Choose Closest Market"
                      onTextChange={this.handleInput}
                      value={location}
                      freeSolo
                      getOptionLabel={(option) => {
                        return option.cityAndState || this.rapInput;
                      }}
                      popupIcon={
                        <Chevron_Down_Icon
                          className={classes.drop_down}
                          // onClick={this.openAccoladeDropdown}
                        />
                      }
                      title={location.cityAndState}
                      options={topLocations || []}
                      ListboxComponent={React.forwardRef(
                        ({ children, ...rest }, ref, itemsLength) => (
                          <CustomScrollbars
                            style={
                              _.get(this.props, "topLocations.length") > 6
                                ? { minHeight: "240px" }
                                : { minHeight: "120px" }
                            }
                            ref={ref}
                            {...rest}
                            itemsLength={
                              topLocations ? topLocations.length : []
                            }
                          >
                            {_.map(topLocations, (trade) => (
                              <MenuItem
                                onMouseUp={this.onLocationChange(trade)}
                              >
                                {trade.cityAndState}
                              </MenuItem>
                            ))}
                          </CustomScrollbars>
                        )
                      )}
                    />
                    <Grid container item>
                      <label className={classes.inputLabelTxt}>
                        {strings.landing_page.titles.placeholder_location}
                      </label>
                    </Grid>
                  </Grid>
                  {/* <Grid item container md={7} xs={7} justify="flex-end">
                    <Grid container item md={1} xs={1}>
                      <CustomCheckbox className={classes.checkbox} />
                    </Grid>
                    <Grid container item md={6} xs={8} alignItems="center">
                      <label className={classes.chkbxLabelStyle}>
                        {" "}
                        {strings.landing_page.titles.highlight_apprenticeships}
                      </label>
                    </Grid>
                    
                    <Grid
                      container
                      item
                      md={1}
                      xs={1}
                      className={classes.pilerAlign}
                    >
                      <img src="/assets/images/Pilar_b.svg" alt="Piler" />
                    </Grid>
                  </Grid> */}
                </Grid>
                <Grid container item alignItems="center">
                  <Grid item md={2} xs={2} alignItems="center"></Grid>
                  <Grid item md={6} xs={6} alignItems="center">
                    <Grid
                      container
                      alignItems="center"
                      className={classes.subContentStyle}
                    >
                      {strings.landing_page.titles.show_skills}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item md={6} xs={6}>
              <Grid container justify="center" className={classes.border}>
                <Grid container item xs justify="center" alignItems="center">
                  <Grid
                    container
                    item
                    md={8}
                    xs={7}
                    justify="flex-end"
                    alignItems="center"
                  >
                    <Grid item justify="center" className={classes.subTxtStyle}>
                      {strings.landing_page.titles.find_talent}
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    item
                    md={2}
                    xs={3}
                    justify="center"
                    alignItems="center"
                    className={classes.userIconAlign}
                  >
                    <Grid item>
                      <SvgUserIcon />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid container justify="center">
                  <Grid
                    container
                    item
                    md={8}
                    xs={11}
                    justify="center"
                    alignItems="center"
                    style={{ position: "relative" }}
                  >
                    {/* <img
                      src="assets/icons/icon_happy_arrow.svg"
                      className={classes.arrow_icon}
                      alt=""
                      onMouseUp={this.communityPostSearch}
                    /> */}
                    <div
                      className={classes.addImageIcon}
                      onClick={this.communityPostSearch}
                    />
                    <Close_Icon
                      className={classes.Close_icon}
                      onMouseUp={this.clearSearch("comunityTitle")}
                    />
                    <CustomTextField
                      variant="outlined"
                      className={classes.subGridInput1}
                      placeholder={strings.landing_page.titles.skills_jobs}
                      onBlur={this.handleInputChange}
                      name="comunityTitle"
                      defaultValue={this.state.comunityTitle}
                      key={this.state.comunityTitle}
                      onKeyPress={this.handleKeypressCommunity}
                    />
                  </Grid>
                  <Grid container item md={8} xs={11} alignItems="center">
                    <Grid item container justify="flex-end">
                      <CustomAutocomplete
                        className={classes.your_location1}
                        placeholder="Choose Closest Market"
                        onTextChange={this.handleInput}
                        value={companyLocation}
                        freeSolo
                        getOptionLabel={(option) => {
                          return option.cityAndState || this.rapInput;
                        }}
                        popupIcon={
                          <Chevron_Down_Icon
                            className={classes.drop_down}
                            // onClick={this.openAccoladeDropdown}
                          />
                        }
                        title={companyLocation.cityAndState}
                        options={topLocations || []}
                        ListboxComponent={React.forwardRef(
                          ({ children, ...rest }, ref, itemsLength) => (
                            <CustomScrollbars
                              style={
                                _.get(this.props, "topLocations.length") > 6
                                  ? { minHeight: "240px" }
                                  : { minHeight: "120px" }
                              }
                              ref={ref}
                              {...rest}
                              itemsLength={
                                topLocations ? topLocations.length : []
                              }
                            >
                              {_.map(topLocations, (trade) => (
                                <MenuItem
                                  onMouseUp={this.onLocationChangeCompany(
                                    trade
                                  )}
                                >
                                  {trade.cityAndState}
                                </MenuItem>
                              ))}
                            </CustomScrollbars>
                          )
                        )}
                      />
                      <Grid container item justify="flex-end">
                        <label className={classes.inputLabelTxt}>
                          {strings.landing_page.titles.placeholder_location}
                        </label>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid container item justify="flex-end" alignItems="center">
                <Grid item md={8} xs={8} justify="flex-end" alignItems="center">
                  <Grid
                    container
                    justify="flex-end"
                    alignItems="center"
                    className={classes.subContentStyle}
                  >
                    {strings.landing_page.titles.appropriate_candidates}
                  </Grid>
                </Grid>
                <Grid item md={2} alignItems="center"></Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item md={12} xs={12}>
            <Grid container alignItems="center">
              <Grid
                container
                item
                md={6}
                xs={6}
                alignItems="center"
                className={classes.btnGridStyle1}
              >
                <Grid item md={2} xs={2} alignItems="center"></Grid>
                <Grid
                  item
                  md={6}
                  xs={6}
                  alignItems="center"
                  className={classes.btnAlign}
                >
                  <CustomButton
                    color="primary"
                    variant="outlined"
                    className={`${classes.btnTxtStyle} ${classes.btnPost_resume}`}
                    onClick={openLoginModal}
                  >
                    {strings.landing_page.titles.post_resume}
                  </CustomButton>
                </Grid>
              </Grid>
              <Grid
                container
                item
                md={6}
                xs={6}
                justify="flex-end"
                alignItems="center"
                className={classes.btnGridStyle}
              >
                <Grid
                  item
                  md={4}
                  xs={4}
                  justify="flex-end"
                  alignItems="center"
                  className={classes.right}
                >
                  <CustomButton
                    color="primary"
                    variant="outlined"
                    className={`${classes.btnTxtStyle} ${classes.btnPost_job}`}
                    onClick={openLoginModal}
                  >
                    {strings.landing_page.titles.post_a_job}
                  </CustomButton>
                </Grid>
                <Grid item md={2} xs={2}></Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item md={12} xs={12} style={{ marginTop: "24px" }}>
            <Grid container alignItems="center">
              <Grid
                container
                item
                md={6}
                xs={6}
                alignItems="center"
                // className={classes.btnGridStyle1}
              >
                <Grid item md={2} xs={2} alignItems="center"></Grid>
                <Grid
                  item
                  md={9}
                  xs={9}
                  alignItems="center"
                  className={classes.btnAlign}
                >
                  <Row
                    className={classes.rowHoverTxtStyle}
                    onClick={this.toggleDemoModal}
                  >
                    <img
                      src="/assets/images/Demo Icon 1.png"
                      className={classes.forgot_email_img}
                    />
                    <Text
                      family="gillsans_sb"
                      size={22}
                      className={classes.hoverTxtStyle}
                      // onClick={this.toggleDemoModal}
                    >
                      Demo Videos to Get You Started!
                    </Text>
                  </Row>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        {openFeedBackModal && (
          <FeedbackModal closeModal={this.toggleFeedBackModal} />
        )}
        {openDemoModal && (
          <DemoModal
            onClose={this.toggleDemoModal}
            openDemoModal={openDemoModal}
          />
        )}
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    topLocations: state.Login.topLocations,
  };
}

export default connect(mapStateToProps)(
  withStyles(useStyles, { withTheme: true })(LandingContent)
);
