import React, { useState } from "react";
import newPasswordStyles from "../../pages/password/styles/newPasswordStyles";
import Text from "../../components/common/ui_kit/text";
import Row from "../../components/common/ui_kit/row";
import CustomModal from "../../components/inputs/custom_modal";
import { color } from "../../utilities/themes";
import Close_Icon from "../../components/data_display/icons/Close";
import VideoDisplayModal from "./VideoDisplayModal";

function DemoModal(props) {
    const { classes, onClose, openDemoVideoModal } = props;
    const [openVideoModal, setOpenVideoModal] = useState(false)
    const [selectedVideoUrl, setSelectedVideoUrl] = useState(false)
    const [selectedModalTitle, setSelectedModalTitle] = useState("")

    const toggleVideoModal = (url, modalTitle) => () => {
        setOpenVideoModal(!openVideoModal)
        setSelectedVideoUrl(url)
        setSelectedModalTitle(modalTitle)
    }

    return (
        <>
            <CustomModal
                open={openDemoVideoModal}
                aria-labelledby="modal-title"
                disablePortal
                aria-describedby="modal-description"
                disableAutoFocus
            >
                <div className={classes.demoModalWidth}>
                    <div>
                        <Close_Icon
                            onClick={onClose}
                            className={classes.closeAlign}
                        />
                    </div>

                    <div className={classes.textCenter}>
                        <img
                            src="/assets/images/Demo Icon 2.png"
                            className={classes.forgot_email_img}
                        />
                    </div>
                    <Text
                        family="gillsans_sb"
                        size={22}
                        className={classes.textCenter}
                    >
                        Choose a Company Join Us Demo
                    </Text>
                    <div style={{ padding: "20px" }}>
                        <div>
                            <Row>
                                <img
                                    src="/assets/images/arrow-icon.png"
                                    className={classes.arrow_icon}
                                />
                                <Text
                                    family="gillsans_r"
                                    size={18}
                                    className={classes.optionsAlign}
                                    onClick={toggleVideoModal("https://player.vimeo.com/video/1054386825?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479", 'Fast Company Join Us & Post Profile')}
                                >
                                    Fast Company Join Us & Post Profile, 7 min
                                </Text>
                            </Row>
                            <Row>
                                <img
                                    src="/assets/images/arrow-icon.png"
                                    className={classes.arrow_icon}
                                />
                                <Text
                                    family="gillsans_r"
                                    size={18}
                                    className={classes.optionsAlign}
                                    onClick={toggleVideoModal("https://player.vimeo.com/video/1054412543?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479", 'Detailed Join Us & Post Profile')}
                                >
                                    Detailed Join Us & Post Profile, 15 min
                                </Text>
                            </Row>
                            <Text
                                family="gillsans_r"
                                size={16}
                                style={{ paddingLeft: "20px" }}
                            >
                                Better for Employees to Find you!
                            </Text>
                        </div>
                    </div>
                </div>
            </CustomModal>
            {openVideoModal && (
                <VideoDisplayModal onClose={toggleVideoModal()} openDemoVideoModal={openVideoModal} videoUrl={selectedVideoUrl} modalTitle={selectedModalTitle} />
            )
            }
        </>
    );
}

export default newPasswordStyles(DemoModal);
