import React from "react";
import newPasswordStyles from "../../pages/password/styles/newPasswordStyles";
import Text from "../../components/common/ui_kit/text";
import Row from "../../components/common/ui_kit/row";
import CustomModal from "../../components/inputs/custom_modal";
import { color, pxToRem } from "../../utilities/themes";
import Close_Icon from "../../components/data_display/icons/Close";
import ReactPlayer from "react-player";

function VideoDisplayModal(props) {
    const { classes, onClose, openDemoVideoModal, videoUrl, modalTitle } = props;

    return (
        <>
            <CustomModal
                open={openDemoVideoModal}
                aria-labelledby="modal-title"
                disablePortal
                aria-describedby="modal-description"
                disableAutoFocus
            >
                <div className={classes.demoVideoModalWidth}>
                    <div>
                        <Close_Icon
                            onClick={onClose}
                            className={classes.closeAlign}
                        />
                    </div>

                    {/* <div className={classes.textCenter}>
                        <img
                            src="/assets/images/Demo Icon 2.png"
                            className={classes.forgot_email_img}
                        />
                    </div> */}
                    <Text
                        family="gillsans_sb"
                        size={22}
                        color={color.primary_palette.franklin_purple}
                        className={classes.textCenter}
                        style={{ marginTop: pxToRem(30) }}
                    >
                        {modalTitle}
                    </Text>

                    <div style={{ padding: "20px" }}>
                        {/* <ReactPlayer
                            url="https://www.youtube.com/watch?v=AFiutX7SGUg"
                            light
                            controls
                            height={"320px"}
                            width={"480px"}
                            className={classes.videoPlayer}
                        /> */}
                        {/* <div style={{ padding: "56.25% 0 0 0", position: "relative" }}><iframe src="https://player.vimeo.com/video/1054040393?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" frameborder="0" allow="autoplay; fullscreen; picture-in-picture; clipboard-write; encrypted-media" style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }} title="ANNIE Demo"></iframe></div><script src="https://player.vimeo.com/api/player.js"></script> */}
                        <div style={{ padding: "56.25% 0 0 0", position: "relative" }}><iframe src={videoUrl} frameborder="0" allow="autoplay; fullscreen; picture-in-picture; clipboard-write; encrypted-media" style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }} title="ANNIE Demo"></iframe></div><script src="https://player.vimeo.com/api/player.js"></script>
                    </div>
                </div>
            </CustomModal >
        </>
    );
}

export default newPasswordStyles(VideoDisplayModal);
